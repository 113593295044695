export const articles = [
  {
    id: 1,
    title: "Leetcode 1512",
    link: "leetcode-1512",
    subtitle: "Number of good pairs -- Easy",
    date: "2023-10-03",
    category: "Leetcode",
    thumbnail: "leetcode.jpg",
  },
  {
    id: 2,
    title: "OOTD - Episode 1",
    link: "ootd-episode-1",
    subtitle: "Describing what is OOTD, my goal behind and the technology choices I made.",
    date: "2023-10-24",
    category: "Project",
    thumbnail: "ootdlogo.jpg",
  },
  {
    id: 3,
    title: "OOTD - Episode 2",
    link: "ootd-episode-2",
    subtitle: "Going throuht the system architecture I decided to create for OOTD. Giving an explaination of my choices",
    date: "2023-11-04",
    category: "Project",
    thumbnail: "ootdlogo.jpg",
  },
  {
    id: 4,
    title: "AWS Lambda - Handling files",
    link: "aws-lambda-files",
    subtitle: "I had to handle binary files in a request that went from API Gateway to a Lambda (in a SAM app). I struggled a little but happy to share my solution.",
    date: "2023-11-14",
    category: "Code",
    thumbnail: "code.jpg",
  },
  {
    id: 5,
    title: "Seoul Tech Impact Hackathon 2023",
    link: "seoul-tech-impact-hackathon",
    subtitle: "Participated in a 24h hackathon. Describing the project we picked, how I lead the technical choices and some things I learnt.",
    date: "2023-11-28",
    category: "Project",
    thumbnail: "seoulTechImpact.jpg",
  },
].reverse();
